import React from "react"
import Img from "gatsby-image"
import styles from "@styles/components/hero.module.css";
import pic from '../images/hi.png';


const Hero = ({ image, text }) => {
  return (
    <div className={styles.container}>
        <Img className={styles.image} fluid={image.childImageSharp.fluid} loading="eager" alt="Vinny Stodder playing piano"/>
        <div className={styles.backdrop}></div>
        <div className={styles.textContainer} >
        <section className={styles.text} dangerouslySetInnerHTML={{ __html: text }}>
        </section>
          <img className={styles.name} src={pic} alt="Vinny as text"/>
        </div>
        
    </div>
  )
}

export default Hero
