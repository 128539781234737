import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Hero from "@components/hero";

const IndexPage = ({data}) => {
  const { frontmatter, html } = data.homepage.edges[0].node;
  return (
  <Layout>
    <SEO title="Musical Composer" />
    <Hero image={frontmatter.image} text={html} />
    <section className="homepageVideo">
      <h2 className="video-title">Check out my new lyric video for my original holiday song <span>I Love You From Everywhere</span></h2>
      <div dangerouslySetInnerHTML={{__html: data.videos.edges[0].node.html}}></div>
    </section>
    
  </Layout>
)}

export default IndexPage


export const pageQuery = graphql`
  {
    homepage: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/home\\\/index.md/"}}) {
      edges {
        node {
          html,
          frontmatter {
            image {
              childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
          }
        }
      }
    }
    videos: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/home\\\/video.md/"}}) {
      edges {
        node {
          html
        }
      }
    } 
  
  }
`;